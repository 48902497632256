
/** shared styles **/

@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap');

%title-text {
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: .3rem;
}

%subtitles {
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: .2rem;
}

html, body, #root {
  font-family: 'Anonymous Pro', monospace;
  scroll-behavior: smooth;
}

#projectPage, #aboutPage, #contactPage {
  h3 {
    @extend %subtitles;
  }
}

/** cursor blob trail **/

.logo-jr {
  display: none;
  position: fixed;
  top: 30px;
  left: 20px;
  width: 30px;
}

.click-for-blob {
  position: fixed;
  top: 21px;
  left: 23px;
  height: 15px;
  width: 15px;
  z-index: 60;
}

.click-for-blob:hover {
  cursor: pointer;
}

.cursor-trail div {
  display: none;
  position: fixed;
  z-index: 50;
  width: 11px;
  height: 11px;
  top: 23px;
  left: 25px;
  background-color: #ca2929;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 1px #ca2929;
}

.bouncing {
  animation: bounce 1.8s ease infinite;
  -webkit-animation: bounce 1.8s ease infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0) }
	40% { transform: translateY(-4px) }
  65% { transform: translateY(-8px) }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0) }
	40% { transform: translateY(-4px) }
  65% { transform: translateY(-8px) }
}

.message {
  position: fixed;
  top: 17px;
  left: 45px;
  font-size: .5rem;
  display: none;
  opacity: 0;
  animation: appear 1.8s ease infinite;
  animation-delay: 15s;
  -webkit-animation: appear 1.8s ease infinite;
  -webkit-animation-delay: 15s;
}

@keyframes appear {
  0% { opacity: 0 }
  50% { opacity: 1; transform: translateX(1px) }
  100% { opacity: 0 }
}

@-webkit-keyframes appear {
  0% { opacity: 0 }
  50% { opacity: 1; transform: translateX(1px) }
  100% { opacity: 0 }
}

/** landing **/

#landingPage {
  background: #E4EAF6;

  h3 {
    @extend %title-text;
  }

  .cut-out {
    position: relative;
    background-color: white;
    height: 480px;
    width: 320px;
    overflow: hidden;
  }

  .landing-svgs {
    position: absolute;
    animation: svgloop linear 8s infinite;
    bottom: 0;
    left: -40px;
  }

  @keyframes svgloop {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(50%);
    }
  }

  .title-jr {
    position: relative;
  }
}

/** nav bar **/

#sideNavbar {
  position: fixed;
  z-index: 20;
  width: 100%;
  font-size: .85rem;

  .nav-item {
    .nav-link {
      color: black;
    } 

    .nav-link:hover {
      color: #ca2929;
    }
  }
}

/** project page **/

#projectPage {

  .project {
    position: relative;
    width: 400px;
    display: inline-block;
  }

  .project-img {
    width: 100%;
  }

  .project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    opacity: 1;
    transition: opacity .8s;

    a {
      width:fit-content;
      text-decoration: none;
      color: #fff;
    }

    a:hover {
      text-decoration: underline;
    }

    p {
      font-size: .7rem;
      overflow-wrap: break-word;
    }
  }

  .project-blur {
    backdrop-filter: blur(10px);
  }

  .project-overlay > * {
    transform: translateY(-20px);
    transition: transform .8s;
  }

  .project-overlay:hover {
    opacity: 1;
  }

  .project-overlay:hover > * {
    transform: translateY(0);
  }

  .project-note, .github-note {
    font-size: .7rem;
    color: #515151;
  }

  .github-note {
    color: black;
    text-decoration: none;
  }

  .github-note:hover {
    text-decoration: underline;
  }


  #logo-svgs {
    transform-origin: center;
    animation: circling 20s linear infinite;
  }

  .project-svgs {
    max-width: 250px;

    .logo-svg {
      transform-box: fill-box;
      transform-origin: center;
      animation: rotating 20s linear infinite;
    }
  }

}

/** about page **/

#aboutPage .about-me, #contactPage .contact-me {
  background-color: #E4EAF6;
}

#aboutPage {

  
  .wave-word span {
    display: inline-block;
    animation: wave .5s ease-in-out infinite;
    animation-delay: calc( 0.1s * var(--letter));
    animation-direction: alternate;
  }
  
  @keyframes wave {
    0% { trasform: translateY(5px); }
    100% { transform: translateY(-5px); }
  }

  #my-hobbies-svgs {
    transform-origin: center;
    animation: circling 20s linear infinite;
    overflow: visible;
    padding-left: 100px;
    
    .about-svg {
      transform-box: fill-box;
      transform-origin: 50% 50%;
      animation: rotating 20s linear infinite;
    }
    
  }
  
  @keyframes circling {
    0% { transform: rotate(0deg)  }
    100% { transform: rotate(360deg) }
  }
  
  @keyframes rotating {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(-360deg) }
  }

  svg {
    overflow: visible;
  }

  .about-svgs {
    max-width: 250px;
  }

  .altcademy-link {
    text-decoration: none;
    color: black;
  }

  .altcademy-link:hover {
    text-decoration: underline;
    color: black;
  }
}

/** contact page **/ 

#contactPage {

  input, textarea {
    border-radius: .5rem;
    border: 2px solid #F4B459;
    width: 70%;
  }

  .contact-me {
    position: relative;
  }

  .contact-button {
    background-color: lightgreen;
    font-size: 1.13rem;
    width: 7rem;
    height: 2.5rem;
    position: absolute;
    bottom: -28px;
  }

  .letter-svgs svg {
    position: absolute;
  }

  #open-letter, #closed-letter {
    left: 30px;
    animation: jumping 1s linear infinite;
  }

  #closed-letter {
    left: unset;
    right: 30px;
    animation-delay: .5s;
  }

  @keyframes jumping {
    0% { transform: translateY(0px) }
    50% { transform: translateY(10px) } 
    100% { transform: translateY(0px) }
  }

}

/** footer **/

#footer {
  p {
    font-size: .5rem;
  }

  .copyright {
    background-color: #E4EAF6;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  a {
    text-decoration: none;
    color: black;
  }
}

/** responsiveness **/

@media (max-width: 350px) {
  #landingPage {
    flex-direction: column;
    .title-jr h3 {
      text-align: start;
      font-size: small;
      letter-spacing: .2rem;
    }
  }
}

@media (max-width: 576px) {
  #projectPage {
    .project {
      width: 100%;
    }
  }
}

@media (min-width: 992px) {

  #sideNavbar {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    height: 50px;
    width: 100vh;
    right: 0;
    margin-left: auto;
    top: -50px;

    .navbar-nav {
      margin: 0 auto;
      background-color: #E4EAF6;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      transform: rotate(180deg) translateY(-100%);
      animation: slide ease 1.3s forwards;
      animation-delay: 1.3s;
    }

    .social-links a {
      transform: rotate(-90deg);
    }

    li {
      margin: 0 .8rem;
    }

    @keyframes slide {
      0% { transform: rotate(180deg) translateY(-100%) }
      100% { transform: rotate(180deg) translateY(0%) }
    }
  }

  #aboutPage .about-svgs, #projectPage .project-svgs {
    max-width: 350px;
  }

  #contactPage {

    .letter-svgs {
      position: static;
    }
    
    .letter-svgs svg {
      position: absolute;
      top: 35%;
    }

    #open-letter, #closed-letter {
      left: -20px;
    }
  
    #closed-letter {
      left: unset;
      right: -20px;
    }
  }

  #footer {
    p {
      font-size: .7rem;
    }
  }
}

/** none touchscreens **/

@media (any-hover: hover) {
  #projectPage {
    .project-overlay {
      opacity: 0;
    }
  }
}

@media (any-hover: hover) and (min-width: 992px) {
  .cursor-trail div, .logo-jr, .click-for-blob, .message {
    display: block;
  }
}

